import styled from 'styled-components'

import mq, { ScreenSize } from 'styles/mq'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import Container from 'components/container'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.color.background.light};

  ${mq.medium} {
    flex-direction: row;
    align-items: center;
  }
`

export const TextContainer = styled(Container as any)`
  padding-top: 16rem;
  padding-bottom: 16rem;

  ${mq.medium} {
    margin: 14rem 0 14rem auto;
    padding-right: 10rem !important;
    max-width: ${ScreenSize.LG / 20}rem;
  }
`

export const Heading = styled.h1`
  ${heading.xxl};
  margin: 0;
`

export const Excerpt = styled.p`
  ${paragraph.normal}
`

export const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 7rem 2rem;
  margin: 8rem 0;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
    margin: 13rem 0;
  }
`

export const SectionHeading = styled.h2`
  ${heading.xl};
  margin: 8rem 0 -5rem;

  ${mq.medium} {
    display: none;
  }
`
