import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import Categories from 'components/categories'
import SEO from 'components/seo'
import Container from 'components/container'
import Post from 'components/blog-post'
import LoadMoreBlog from 'components/load-more-blog'
import ScrollToTopContainer from 'components/scroll-to-top'
import Carousel from './carousel'
import {
  Header,
  TextContainer,
  Excerpt,
  Heading,
  Posts,
  SectionHeading,
} from './styled'

type Props = {
  data: {
    allMarkdownRemark: {
      edges: ReadonlyArray<{
        node: BlogPost
      }>
    }
    allCategoriesJson: {
      nodes: readonly BlogCategory[]
    }
  }
  pageContext: {
    slug: string
    pagePosts: readonly BlogPost[]
    currentPage: number
    countPages: number
  }
}

const BlogCategory = ({ data, pageContext }: Props) => {
  const categories = data.allCategoriesJson.nodes
  const activeCategory = data.allCategoriesJson.nodes.filter(
    cat => pageContext.slug === cat.slug
  )[0]
  const featuredPosts = data.allMarkdownRemark.edges

  return (
    <Layout withHeaderBorder>
      <SEO title={`${activeCategory.title}`} />
      <Categories categories={categories} current={pageContext.slug} />

      <Header>
        <TextContainer>
          <Heading>{activeCategory.title}</Heading>
          <Excerpt>{activeCategory.description}</Excerpt>
        </TextContainer>

        <Carousel posts={featuredPosts} />
      </Header>

      <ScrollToTopContainer>
        <Container>
          <SectionHeading>Latest</SectionHeading>

          <LoadMoreBlog
            pageContext={pageContext}
            renderItem={node => <Post post={node} key={node.fields.slug} />}
            WrapperComponent={Posts}
            collection="by-category"
            noContentMessage="There are no posts in this category yet."
          />
        </Container>
      </ScrollToTopContainer>
    </Layout>
  )
}

export default BlogCategory

export const pageQuery = graphql`
  query BlogPostsByCategorySlug($slug: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          featureIndex: { gte: 0 }
          category: { slug: { eq: $slug } }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }
    allCategoriesJson {
      nodes {
        ...categoryFields
      }
    }
  }
`
