import styled from 'styled-components'
import Img from 'gatsby-image'

import mq, { ScreenSize } from 'styles/mq'
import { heading, paragraph } from 'styles/typography'
import ContainerBase from 'components/container'

export const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  background: #eee;

  ${mq.medium} {
    width: 50%;
  }
`

export const Slide = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 16rem 0;

  ${mq.medium} {
    padding: 8rem 0;
    min-height: 75rem;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.67), transparent);
  }
`

export const SlideText = styled(ContainerBase as any)`
  color: white;
  z-index: 1;

  ${mq.large} {
    margin: 0;
    padding-left: 13rem !important;
    max-width: ${ScreenSize.LG / 20}rem;
  }
`

export const SlideTitle = styled.h2`
  ${heading.xl};
  margin: 0;
`

export const SlideExcerpt = styled.p`
  ${paragraph.small}
  margin-bottom: 3rem;
`

export const Thumbnail = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
