import React, { FC } from 'react'

import { PrimaryLink } from 'components/links'
import FeaturedSlider from 'components/featured-slider'
import { ScreenSize } from 'styles/mq'
import {
  Container,
  Slide,
  SlideTitle,
  SlideExcerpt,
  SlideText,
  Thumbnail,
} from './styled'

type Props = {
  posts: ReadonlyArray<{
    node: BlogPost
  }>
}

const Carousel: FC<Props> = ({ posts }) => (
  <Container>
    <FeaturedSlider>
      {posts.map(({ node }) => (
        <Slide key={node.fields.slug}>
          <Thumbnail
            // @ts-expect-error
            fluid={{
              ...node.frontmatter.thumbnail.childImageSharp.fluid,
              sizes: `(max-width: ${ScreenSize.SM}px) 100vw, 50vw`,
            }}
          />
          <SlideText>
            <SlideTitle>{node.frontmatter.title}</SlideTitle>
            <SlideExcerpt>{node.excerpt}</SlideExcerpt>
            <PrimaryLink to={node.fields.slug} inverse>
              Read Now
            </PrimaryLink>
          </SlideText>
        </Slide>
      ))}
    </FeaturedSlider>
  </Container>
)

export default Carousel
